import React, { useState, useCallback, useEffect, useReducer, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Helmet } from 'react-helmet';
import "./AnimalGallery.css";

const animals = [
  "Frog", "Duck", "Raccoon", "Horse", "Elephant", "Snake", "Rat", "Chicken", "Cow",
  "Dog", "Sheep", "Pig", "Bird", "Cat", "Rabbit", "Unicorn", "Monkey", "Dragon", "Lemur",
  "Bear"
].sort();

const GROWTH_FACTOR = 1.5;
const MAX_SIZE = 500;
const MAX_ANIMALS = 50;

const animalReducer = (state, action) => {
  switch (action.type) {
    case "ADD_ANIMAL":
      return state.length >= MAX_ANIMALS ? state : [...state, action.payload];
    case "UPDATE_ANIMAL":
      return state.map(animal => 
        animal.id === action.payload.id ? { ...animal, ...action.payload } : animal
      );
    case "REMOVE_ANIMAL":
      return state.filter(animal => animal.id !== action.payload);
    case "RESET":
      return [];
    default:
      return state;
  }
};

const AnimalComponent = React.memo(({ animal, onClick, isGrowing }) => {
  return (
    <motion.div
      onClick={() => onClick(animal.id)}
      style={{
        width: `${animal.size}px`,
        height: `${animal.size}px`,
        position: 'absolute',
        left: `${animal.x}px`,
        top: `${animal.y}px`,
      }}
      initial={{ opacity: 0, scale: 0 }}
      animate={{ 
        opacity: 1, 
        scale: 1,
        transition: { duration: isGrowing ? 0.2 : 0.5 }
      }}
      exit={{ opacity: 0, scale: 0 }}
      className={`image-container ${animal.exploding ? "exploding" : ""}`}
    >
      <img
        src={`/imgs/${animal.type.toLowerCase()}.png`}
        alt={`${animal.type} growing`}
        className="animal-image"
        onError={(e) => {
          console.error(`Failed to load image for ${animal.type}`);
          e.target.src = '/imgs/placeholder.png';
        }}
      />
    </motion.div>
  );
});

const ColorButton = ({ color, onClick }) => (
  <button
    className="color-button"
    style={{ backgroundColor: color }}
    onClick={() => onClick(color)}
    aria-label={`Change background to ${color}`}
  />
);

const AnimalGallery = () => {
  const [animalInstances, dispatch] = useReducer(animalReducer, []);
  const [backgroundColor, setBackgroundColor] = useState('#ffffff');
  const containerRef = useRef(null);
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });
  const [growingAnimalId, setGrowingAnimalId] = useState(null);

  const updateContainerSize = useCallback(() => {
    if (containerRef.current) {
      setContainerSize({
        width: containerRef.current.offsetWidth,
        height: containerRef.current.offsetHeight,
      });
    }
  }, []);

  useEffect(() => {
    updateContainerSize();
    window.addEventListener('resize', updateContainerSize);
    return () => window.removeEventListener('resize', updateContainerSize);
  }, [updateContainerSize]);

  const IMAGE_SIZE = 50; // Size of the image

const generateRandomPosition = useCallback((size) => {
  const margin = 50; // Margin from the edges
  const availableWidth = containerSize.width - size - 2 * margin;
  const availableHeight = containerSize.height - size - 2 * margin;

  // Generate random position within the container with margins
  const x = Math.random() * availableWidth;
  const y = Math.random() * availableHeight;

  return { x, y };
}, [containerSize]);

const isPositionValid = (newX, newY, size, existingAnimals) => {
  return existingAnimals.every(({ x, y, size: existingSize }) => {
    const distanceX = Math.abs(newX - x);
    const distanceY = Math.abs(newY - y);
    const minDistance = size / 2 + existingSize / 2 + 20; // Padding to avoid overlap

    return distanceX >= minDistance && distanceY >= minDistance;
  });
};

const generatePositionWithCollisionAvoidance = useCallback((size, existingAnimals) => {
  let position;
  let attempts = 0;
  const maxAttempts = 100; // Limit attempts to avoid infinite loop

  do {
    position = generateRandomPosition(size);
    attempts++;
  } while (!isPositionValid(position.x, position.y, size, existingAnimals) && attempts < maxAttempts);

  return position;
}, [generateRandomPosition]);

const createAnimal = useCallback((type) => {
  const position = generatePositionWithCollisionAvoidance(IMAGE_SIZE, animalInstances);
  return {
    id: `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`,
    type,
    ...position,
    size: IMAGE_SIZE,
  };
}, [generatePositionWithCollisionAvoidance, animalInstances]);


  const handleAnimalClick = useCallback((animalType) => {
    if (animalInstances.length < MAX_ANIMALS) {
      dispatch({ type: "ADD_ANIMAL", payload: createAnimal(animalType) });
    }
  }, [animalInstances.length, createAnimal]);

  const handleImageClick = useCallback((id) => {
    const animal = animalInstances.find(a => a.id === id);
    if (!animal) return;

    setGrowingAnimalId(id);

    const newSize = animal.size * GROWTH_FACTOR;
    if (newSize >= MAX_SIZE) {
      dispatch({ type: "UPDATE_ANIMAL", payload: { id, size: newSize, exploding: true } });
      setTimeout(() => {
        dispatch({ type: "REMOVE_ANIMAL", payload: id });
        dispatch({ type: "ADD_ANIMAL", payload: createAnimal(animal.type) });
        setGrowingAnimalId(null);
      }, 500);
    } else {
      dispatch({ type: "UPDATE_ANIMAL", payload: { id, size: newSize } });
      
      if (containerRef.current) {
        const container = containerRef.current;
        const animalCenterX = animal.x + newSize / 2;
        const animalCenterY = animal.y + newSize / 2;
        
        container.scrollTo({
          left: animalCenterX - container.clientWidth / 2,
          top: animalCenterY - container.clientHeight / 2,
          behavior: 'smooth'
        });
      }

      setTimeout(() => setGrowingAnimalId(null), 200);
    }
  }, [animalInstances, createAnimal]);

  const handleRestart = useCallback(() => {
    dispatch({ type: "RESET" });
  }, []);

  const handleColorChange = useCallback((color) => {
    setBackgroundColor(color);
  }, []);

  const colors = [
    "#FF6F61", "#6B5B95", "#88B04B", "#F7CAC9", "#92A8D1", "#955251", "#B565A7", "#009B77",
    "#DD4124", "#D65076", "#45B8AC", "#EFC050", "#5B5EA6", "#9B2335", "#DFCFBE", "#FFFFFF"
  ];

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Game",
    "name": "Feed the Animals",
    "description": "An interactive game where you feed and grow various animals. Click on animals to make them grow and watch them multiply!",
    "genre": "Educational",
    "numberOfPlayers": {
      "@type": "QuantitativeValue",
      "minValue": 1,
      "maxValue": 1
    },
    "playMode": "SinglePlayer",
    "applicationCategory": "Game",
    "url": "https://feed-the-animals.com"
  };

  return (
    <div className="animal-gallery-wrapper" style={{ backgroundColor }}>
      <Helmet>
        <title>Feed the Animals - Interactive Animal Growth Game</title>
        <meta name="description" content="Play Feed the Animals, an interactive game where you click to feed and grow various animals. Watch them grow and multiply in this fun, educational experience!" />
        <meta name="keywords" content="animal game, interactive animals, educational game, virtual pet, animal growth simulation" />
        <link rel="canonical" href="https://feed-the-animals.com" />
        <meta property="og:title" content="Feed the Animals - Interactive Animal Growth Game" />
        <meta property="og:description" content="Click to feed and grow animals in this fun, interactive game. Watch them multiply as they reach full size!" />
        <meta property="og:image" content="https://feed-the-animals.com/og-image.jpg" />
        <meta property="og:url" content="https://feed-the-animals.com" />
        <meta name="twitter:card" content="summary_large_image" />
        <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
      </Helmet>
      <main className="animal-gallery">
        <h1>Feed the Animals</h1>
        <p>Click on an animal to add it to the game.</p>
        <p>Then click on the animals to feed and grow them!</p>
        <nav className="button-container">
          {animals.map((animal) => (
            <button
              key={animal}
              className="animal-button"
              onClick={() => handleAnimalClick(animal)}
              disabled={animalInstances.length >= MAX_ANIMALS}
            >
              {animal}
            </button>
          ))}
          <button className="restart-button" onClick={handleRestart}>Restart</button>
        </nav>
        <div className="color-grid">
          {colors.map((color, index) => (
            <ColorButton key={index} color={color} onClick={handleColorChange} />
          ))}
        </div>
        <div ref={containerRef} className="animals-container">
          <AnimatePresence>
            {animalInstances.map((animal) => (
              <AnimalComponent
                key={animal.id}
                animal={animal}
                onClick={handleImageClick}
                isGrowing={animal.id === growingAnimalId}
              />
            ))}
          </AnimatePresence>
        </div>
      </main>
      <footer className="footer">
        <p className="locatus">Locatus Games</p>
        <p>© 2024 Feed the Animals. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default AnimalGallery;